import React  from 'react';
import { Markup } from 'interweave';

import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

const SmallBoxInfoLeft = ({h1,p,img,alt, html}) => {


  let isH1=false
  if (typeof  h1 !== "undefined")  isH1=true
  let isP=false
  if (typeof  p !== "undefined")  isP=true
  let isHtml=false
   if (typeof html !== "undefined")  {
    isHtml=true
   }
   
  
  return (
    <div className="p-2 shadow-2xl rounded-2xl">
      <span className="hidden px-4 py-px text-sm font-semibold bg-yellow-300 rounded-full text-darken">
        NEWS
      </span>
      {isH1 && <h1 className="p-2 mb-8 text-xl text-gray-800 rounded-2xl">{h1}</h1>}
      {isP && <p className="mb-3 text-gray-500 ">{p}</p>}
      {isHtml && <Markup content={html} />}
      {/* <a href="" className="hidden underline">
        Read more
      </a> */}
      <PhotoProvider  bannerVisible={true} >
        <PhotoView src={img}>
          <img className="cursor-zoom-in" src={img} style={{ objectFit: "cover" }} alt={alt} />
        </PhotoView>
      </PhotoProvider>
      {/* <img
            className="w-full p-2 mt-6 border rounded-xl"
            src={img}
            alt={alt}
          /> */}
    </div>
  );
};

export default SmallBoxInfoLeft;
