import React from "react";

import SmallBoxInfoLeft from "../tools/SmallBoxInfoLeft";
import SmallBoxInfoRight from "../tools/SmallBoxInfoRight";
import SmallLinks from "../tools/SmallLinks";
import VideoBox from "../tools/VideoBox";
import CrmLinks from "../crm/CrmLinks";
import SodLinks from "./SodLinks";

const SodFlowConfig = ({ fullInfo = false, links = false, without }) => {
  return (
    <div id="flowConfig" className="containerMontemoro">
      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div data-aos="fade-right" className="relative sm:w-1/2">
          <div className="absolute z-0 w-12 h-12 bg-yellow-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
            Konfiguracja Obiegu
          </h1>
        </div>
      </div>

      <div
        data-aos="zoom-in-up"
        className="flex flex-col my-14 lg:flex-row lg:space-x-20"
      >
        <div className="lg:w-6/12">
          <SmallBoxInfoLeft
            h1="Poniżej pokazano przykład zdefiniowanego obiegu dokumentu dla Faktury Zakupu. Poszczególne kolorowe boksy to departamenty. W tym przykładzie Departament Administracji (ADM) wprowadza dokument do obiegu.
             Po wprowadzeniu dokumentu może go przesłać do Departamentu Technicznego (DT), do Działu Handlowego(DH), do grupy JAMES, lub do Głównego Księgowego. Załóżmy, że faktura trafiła do DT. Pracownik DT nie ma wyboru i musi ją skierować do GK.
             W kolejnym kroku główny Księgowy (GK) przesyła dokument do akceptacji do Zarządu (SEO) lub jeżeli uzna, że nie potrzeba akceptacji Zarządu to kończy obieg."
            img="img/sod/flow-fz.png"
          />
          <SmallLinks
            link="sod-konfiguracja-obiegu"
            title="Jak zdefiniować taki obieg zobacz tutaj"
            seeMore={true}
            show={!fullInfo}
          />
        </div>

        <div className="flex flex-col justify-between mt-12 space-y-5 lg:w-7/12 lg:space-y-0 lg:mt-0">
          <SmallBoxInfoRight
            img="img/sod/flow-tool.png"
            h1="Konfiguracja opbiegu odbywa się za pomocą przyjaznego narzędzia graficznego"
          />

          <SmallBoxInfoRight
            img="img/sod/flow-error.png"
            h1="Narzędzie to sprawdza czy obieg został prawidłowo przeprowadzony."
            // p="Więcej o słownikach w dalszej częsci opisu."
          />

          <SmallBoxInfoRight
            img="img/sod/flow-default.png"
            h1="Dla poszczególnych kroków możemy zdefiniować domyślne teksty i checkboxy usprawniające obieg"
          />
          <SmallBoxInfoRight
            img="img/sod/flow-back.png"
            h1="Na każdym etapie obiegu, w przypadku stwierdzenia nieprawidłowości, użytkownik może odesłać sprawę do kroku poprzedniego."
          />
        </div>
        
      </div>
      {fullInfo && (
        <VideoBox
          h1="Zobacz film w jaki sposób dodaliśmy konfigurację tej Faktury Zakupu:"
          video="sod/sod-add-flow"
        />
      )}
      {links && <SodLinks without={without} />}
    </div>
  );
};

export default SodFlowConfig;
