import React  from 'react';

const Tools = () => {
  return (
    <div className="containerMontemoro">
    <div id="tools" className="flex flex-col items-center mt-16 md:flex-row md:space-x-10">
    <div data-aos="fade-right" className="md:w-1/2 lg:pl-14">
      <h1 className="text-3xl font-semibold text-darken lg:pr-56"><span className="text-yellow-500">Tools</span> For Teachers And Learners</h1>
      <p className="my-4 text-gray-500 lg:pr-32">Class has a dynamic set of teaching tools built to be deployed and used during class. Teachers can handout assignments in real-time for students to complete and submit.</p>
    </div>
    <img data-aos="fade-left" className="md:w-1/2" src="img/girl-with-books.png" />
  </div>
  </div>
  );
};

export default Tools;
