import React  from 'react';

import { BsChevronDoubleDown } from "react-icons/bs";

const SeeMore = ( {title = "Zobacz także"}) => {
  return (
    <div className="containerMontemoro">
    <button
    data-aos="flip-up"
    className="block px-5 py-3 mx-auto font-medium text-[#457a8f] transition duration-300 ease-in-out transform border border-[#457a8f] rounded-full mt-14 focus:outline-none hover:scale-110"
  >
    {title}
  </button>
  <BsChevronDoubleDown className="text-[#457a8f] text-3xl justify-center mx-auto animate-bounce mt-3" />
  </div>
  );
};

export default SeeMore;
