import React  from 'react';

const EveryThing = () => {
  return (
    <div className="containerMontemoro">
      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div data-aos="fade-right" className="relative sm:w-1/2">
          <div className="absolute z-0 w-12 h-12 bg-orange-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
            Everything you can do in a physical classroom,{" "}
            <span className="text-yellow-500">you can do with Skilline</span>
          </h1>
          <p className="py-5 lg:pr-32">
            Skilline’s school management software helps traditional and online
            schools manage scheduling, attendance, payments and virtual
            classrooms all in one secure cloud-based system.
          </p>
          <a href="#" className="underline">
            Learn More
          </a>
        </div>
        <div data-aos="fade-left" className="relative mt-10 sm:w-1/2 sm:mt-0">
          <div className="absolute z-0 w-24 h-24 rounded-lg floating -top-3 -left-3 bg-[#23BDEE]"></div>
          <img
            className="relative z-40 rounded-xl"
            src="img/teacher-explaining.png"
            alt=""
          />
          <button className="absolute z-50 flex items-center justify-center transition duration-300 ease-in-out transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full w-14 h-14 top-1/2 left-1/2 focus:outline-none hover:scale-110">
            <svg
              className="w-5 h-5 ml-1"
              viewBox="0 0 24 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5751 12.8097C23.2212 13.1983 23.2212 14.135 22.5751 14.5236L1.51538 27.1891C0.848878 27.5899 5.91205e-07 27.1099 6.25202e-07 26.3321L1.73245e-06 1.00123C1.76645e-06 0.223477 0.848877 -0.256572 1.51538 0.14427L22.5751 12.8097Z"
                fill="#23BDEE"
              />
            </svg>
          </button>
          <div className="absolute z-10 w-40 h-40 bg-yellow-500 rounded-lg floating -bottom-3 -right-3"></div>
        </div>
      </div>
    </div>
  );
};

export default EveryThing;
