import React  from 'react';

import { FaBars } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { FcDoughnutChart } from "react-icons/fc";
import { FcBullish } from "react-icons/fc";
import { FcReading } from "react-icons/fc";
import { FcVoicePresentation } from "react-icons/fc";
import { FcWorkflow } from "react-icons/fc";
import { FcRating } from "react-icons/fc";

import { useState } from "react";
import { Drawer, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { SeoTab } from '../../config/Config';

const NavBarMain = () => {
  const isOpen  = window.innerWidth > 960 ? true : false;
  const [openMenu, setOpenMenu] = useState(false);

  
  


  const closeMenuHandler = () => {
    setOpenMenu(false);
  }
  
  return (
    <nav
      id="home"
      className="flex flex-wrap items-center justify-between max-w-6xl pt-2 pb-4 mx-auto mb-7 "
    >
      <div className="w-full lg:w-1/5 ">
        <a href="/">
          <img
            className="w-full hover:opacity-50"
            src="img/montemoro.png"
            alt="Montemoro - producent oprogramowania"
          />
        </a>
      </div>
      <div className="w-full pt-4 mt-2 lg:w-4/5 hover:opacity-50">
          <FaBars
            className={`w-20 h-20 p-1 mx-auto rounded text-2xl border-3 text-orange-500 bg-orange-100 ${
              isOpen ? "hidden" : "block"
            }`}
            onClick={() => {
              setOpenMenu(true);
            }}
          />
        <div  className={` ${
          isOpen ? "block" : "hidden"
        }`}> 
          
        <AppMenu 
      
        closeMenuHandler={closeMenuHandler} 
        />
        </div>
        <Drawer className="w-full"
          placement="left"
          open={openMenu}
          closable={false}
          bodyStyle={{ backgroundColor: "darkorange", width: "100%", }}
        >
          <AppMenu isInline closeMenuHandler={closeMenuHandler} />
        </Drawer>
      </div>
    </nav>
  );
};



const AppMenu = ({ isInline = false, closeMenuHandler }) => {
  const navigate = useNavigate();
  const clickMenuHandler = (props) => {
    navigate(props.key)
    closeMenuHandler(true);
  

  }

  let childerCrm =SeoTab.filter(pos => pos.cat === "crm")
 

  
  let childerSod =SeoTab.filter(pos => pos.cat === "sod")
  
  

  return (
    <Menu 
    style={{justifyContent: 'flex-end'}}
    onClick={clickMenuHandler}
      mode={isInline ? "inline" : "horizontal"}
      items={[
        {
          label: "Home",
          key: "/",
          icon: <AiOutlineHome />,
         
        },
        {
          label: "Nasze programy",
          key: "/software",
          icon: <FcDoughnutChart />,
         
        },
        {
          label: "CRM",
          key: "/crm",
          icon: <FcBullish />,
          children: childerCrm,
        },
        {
          label: "SOD",
          key: "/sod",
          icon: <FcWorkflow />,
          children: childerSod,
        },
        {
          label: "Oferta",
          key: "/oferta",
          icon: <FcRating />,
        },
        {
          label: "O nas",
          key: "/o-nas",
          icon: <FcReading />,
        },
        {
          label: "Kontakt",
          key: "/kontakt",
          icon: <FcVoicePresentation />,
        },
        
      ]}
    />
  );
};

export default NavBarMain;
