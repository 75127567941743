import React  from 'react';

import SmallBoxInfoLeft from "../tools/SmallBoxInfoLeft";
import SmallBoxInfoRight from "../tools/SmallBoxInfoRight";
import SmallLinks from "../tools/SmallLinks";
import VideoBox from "../tools/VideoBox";
import CrmLinks from "./CrmLinks";

const CrmProjectSchedule = ({ fullInfo = false, links = false, without }) => {
  return (
    <div className="containerMontemoro">
      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div data-aos="fade-right" className="relative sm:w-1/2">
          <div className="absolute z-0 w-12 h-12 bg-yellow-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
            Edycja harmonogramu przychodów
          </h1>
        </div>
      </div>

      <div
        data-aos="zoom-in-up"
        className="flex flex-col my-14 lg:flex-row lg:space-x-20"
      >
        <div className="lg:w-6/12">
          <SmallBoxInfoLeft
            h1="Istotną sprawą dla projektu jest określenie harmonogramu przychodów. Funkcja ta umożliwia planowanie przychodów a następnie śledzenie postępów w realizacji."
            img="img/crm/harmonogram.png"
          />
           <SmallLinks
            link="crm-harmonogram-przychodow"
            title = "Zobacz więcej o harmonogramie przychodów"
            seeMore={true}
            show = {!fullInfo}
          />
        </div>

        <div className="flex flex-col justify-between mt-12 space-y-5 lg:w-6/12 lg:space-y-0 lg:mt-0">
          <SmallBoxInfoRight
            img="img/crm/harmonogram-edit-one.png"
            h1="W przypadku cyklicznych przychodów mamy możliwość wprowadzenia planowanej kwoty oraz miesiąca początkowego i miesiąca końcowego"
          />

          <SmallBoxInfoRight
            img="img/crm/harmonogram-edit-one.png"
            h1="W przypadku pojedyńczego przychodu lub przychodów nieregularnych możemy wprowadzić przychody dla konkretnego miesiąca"
          />
        </div>
      </div>
      {fullInfo && (
        <VideoBox
          h1="Zobacz film z edycji harmonogramu przychodu"
          video="crm/project-schedule2"
        />
      )}
      {links && <CrmLinks without={without} />}
    </div>
  );
};

export default CrmProjectSchedule;
