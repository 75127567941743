import React  from 'react';

const Terms = () => {
  return (
    <div className="containerMontemoro">


      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div data-aos="fade-right" className="relative sm:w-1/2">
          <div className="absolute z-0 w-12 h-12 bg-orange-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
            Klauzula informacyjna o przetwarzaniu danych osobowych dla klientów
            i ich przedstawicieli
          </h1>
          <p className="py-5 lg:pr-32">
            Zgodnie z art. 3 ust. 1 - 2 Rozporządzenia Parlamentu Europejskiego
            i Rady (UE) 2016/679 z dnia 27.04.2016 r. w sprawie ochrony osób
            fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
            swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
            (ogólne rozporządzenie o ochronie danych) zwanego dalej w skrócie
            „RODO”, informujemy iż:
          </p>
     
        </div>
        <div data-aos="fade-left" className="relative mt-10 sm:w-1/2 sm:mt-0">
          <div className="absolute z-0 w-24 h-24 rounded-lg floating -top-3 -left-3 bg-[#23BDEE]"></div>
          <img
            className="relative z-40 rounded-xl"
            src="img/doc3.jpg"
            alt=""
          />

          <div className="absolute z-10 w-40 h-40 bg-yellow-500 rounded-lg floating -bottom-3 -right-3"></div>
        </div>
      </div>




      <div className="items-center mt-0 sm:flex sm:space-x-8">
        <div data-aos="fade-right" className="relative sm:w-1/2">
        
         
          <p className="py-5 lg:pr-32">
            <ul className="list-disc">
          <li>
              realizacją obowiązków podatkowych wynikających z przepisów
              podatkowych (art. 6 ust. 1 lit. c RODO),
            </li>
            <li>
              prawnie uzasadnionym interesem Administratora, polegającym m.in.
              na &nbsp;zapewnieniu ciągłego
              <br />i niezakłóconego prowadzenia działalności, wewnętrznych
              celów administracyjnych (w tym zarządzania usługami),
              &nbsp;niezbędnych rozliczeń w związku z zawartą umową, dochodzeniu
              roszczeń oraz obronie przed roszczeniami.
            </li>
            <li>
              <strong>Odbiorcą danych osobowych mogą być wyłącznie </strong>
              podmioty uprawnione do ich przetwarzania na podstawie przepisów
              prawa, oraz podmioty wspierające w wypełnianiu uprawnień i
              obowiązków (obsługa księgowa, prawna).
            </li>
            <li>
              Administrator nie przekazuje danych osobowych, do państwa
              trzeciego lub organizacji międzynarodowej.
            </li>
            <li>
              Dane osobowe będą przechowywane jedynie w okresie niezbędnym do
              spełnienia celu, dla którego zostały zebrane lub w okresie
              wskazanym przepisami prawa. Po spełnieniu celu, dla którego
              zostały zebrane, mogą one być przechowywane jedynie w celach
              archiwalnych, przez okres, który wyznaczony zostanie na podstawie
              przepisów prawa.
            </li>
            <li>
              Podanie przez Państwa danych osobowych jest dobrowolne, ale
              konieczne do zawarcia i realizacji umowy. Dane osobowe nie będą
              podlegały zautomatyzowanym decyzjom, w szczególności profilowaniu.
            </li>
          </ul>
		  
          </p>
     
        </div>
        <div data-aos="fade-left" className="relative mt-10 sm:w-1/2 sm:mt-0">
          <div className="absolute z-0 w-24 h-24 rounded-lg floating -top-3 -left-3 bg-[#23BDEE]"></div>
          <img
            className="relative z-40 rounded-xl"
            src="img/doc1.jpg"
            alt=""
          />

          <div className="absolute z-10 w-40 h-40 bg-yellow-500 rounded-lg floating -bottom-3 -right-3"></div>
        </div>
      </div>




      <div className="items-center mt-0 sm:flex sm:space-x-8">
        <div data-aos="fade-right" className="relative sm:w-1/2">
        
         
            <h1 className="mt-8 mb-2 font-semibold">Administratorem Państwa danych osobowych jest:</h1>
          <p className="mt-0 mb-4 font-semibold">MONTEMORO | ul. Żupańskiego 3/4 | 61-562 Poznań.</p>
         
          <ul className="list-disc">
            <li>
              Administrator nie wyznaczył Inspektora Ochrony Danych. We
              wszelkich sprawach związanych z ochroną danych osobowych może się
              Pani/Pan skontaktować z Administratorem w następujący sposób:
            </li>
            <li>pod adresem poczty elektronicznej: biuro@montemoro.pl</li>
            <li>pod numerem telefonu: +48 694 230 722</li>
            <li>
              pisemnie na adres: &nbsp;ul. Żupańskiego 3/4 | 61-562 Poznań
            </li>
            <li>
              Dane osobowe klientów będących osobami fizycznymi będą
              przetwarzane w celach związanych z:
            </li>
            <li>
              realizacją umowy - &nbsp;w zakresie niezbędnym do wykonania umowy
              (art. 6 ust. 1 lit. b RODO),
            </li>
            <li>
              realizacją obowiązków wynikających z ustawy o rachunkowości (art.
              6 ust. 1 lit. c RODO),
            </li>
 </ul>
		  
     
        </div>
        <div data-aos="fade-left" className="relative mt-10 sm:w-1/2 sm:mt-0">
          <div className="absolute z-0 w-24 h-24 rounded-lg floating -top-3 -left-3 bg-[#23BDEE]"></div>
          <img
            className="relative z-40 rounded-xl"
            src="img/doc2.jpg"
            alt=""
          />

          <div className="absolute z-10 w-40 h-40 bg-yellow-500 rounded-lg floating -bottom-3 -right-3"></div>
        </div>
      </div>





      <div className="items-center mt-20 sm:flex sm:space-x-8">
        <div data-aos="fade-right" className="relative sm:w-1/2">
        
         
        <h1 className="mt-8 mb-2 font-semibold">
            Na zasadach określonych przepisami RODO, posiadają Państwo prawo do:
          </h1>
          <ol className="list-disc">
            <li>dostępu do treści danych,</li>
            <li>sprostowania danych,</li>
            <li>usunięcia danych,</li>
            <li>ograniczenia przetwarzania danych,</li>
            <li>przenoszenia danych,</li>
            <li>
              wniesienia sprzeciwu wobec przetwarzania danych w związku ze
              szczególną sytuacją,
            </li>
            <li>
              wniesienia skargi do organu nadzorczego, którym jest Prezes Urzędu
              Ochrony Danych Osobowych,
            </li>
            <li>
              cofnięcia zgody w dowolnym momencie. Wycofanie zgody nie ma wpływu
              na przetwarzanie danych do momentu jej wycofania.
            </li>
          </ol>
          {/* <p>Maciej Petza</p> */}
		  
     
        </div>
        <div data-aos="fade-left" className="relative mt-10 sm:w-1/2 sm:mt-0">
          <div className="absolute z-0 w-24 h-24 rounded-lg floating -top-3 -left-3 bg-[#23BDEE]"></div>
          <img
            className="relative z-40 rounded-xl"
            src="img/doc4.jpg"
            alt=""
          />

          <div className="absolute z-10 w-40 h-40 bg-yellow-500 rounded-lg floating -bottom-3 -right-3"></div>
        </div>
      </div>


      
    </div>
  );
};

export default Terms;
