import React from "react";


import SmallBox from "../components/SmallBox";

const StartPage = () => {
  return (
    <div className="bg-cream">
      <div className="items-start max-w-screen-xl px-8 mx-auto sm:flex-col sm:flex lg:flex-row">
        {/* <!--Left Col--> */}
        <div className="flex flex-col items-start justify-center w-full mb-5 text-center lg:w-6/12 lg:pt-24 lg:text-left md:mb-0">
          <h1
            data-aos="fade-right"
            data-aos-once="true"
            className="my-4 text-lg font-bold leading-tight sm:text-5xl textOrange"
          >
            <span className="text-white ">Montemoro</span> Producent
            oprogramowania dla Przedsiębiorstw.
          </h1>
          <p
            data-aos="fade-down"
            data-aos-once="true"
            data-aos-delay="300"
            className="mb-8 leading-normal sm:text-2xl text-[#ffffff]"
          >
            Zachęcamy do współpracy i zapoznania się z naszą ofertą.
          </p>
          <div
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="700"
            className="items-center justify-center w-full md:flex lg:justify-start md:space-x-5"
          >
            <a href="#software1">
              <button className="py-4 m-2 font-bold text-white transition duration-300 ease-in-out transform rounded-full bgOrange sm:text-xl sm:mb-3 lg:mx-0 px-9 focus:outline-none hover:scale-110">
                Zobacz nasze programy
              </button>
            </a>
            <a href="/oferta">
              <button className="py-4 m-2 font-bold text-white transition duration-300 ease-in-out transform rounded-full bgOrange sm:text-xl sm:mb-3 lg:mx-0 px-9 focus:outline-none hover:scale-110">
                i naszą ofertę
              </button>
            </a>
            <div className="flex items-center justify-center mt-5 space-x-3 transition duration-300 ease-in-out transform md:mt-0 focus:outline-none hover:scale-110"></div>
          </div>
        </div>
        {/* <!--Right Col--> */}
        <div className="relative w-full lg:w-6/12 lg:-mt-10" id="girl">
          <img
            data-aos="fade-up"
            data-aos-once="true"
            className="w-10/12 mx-auto rounded"
            src="img/cat.jpg"
            alt="Montemoro - programy CRM i inne"
          />

      <SmallBox />

      

          {/* <!-- red --> */}
          <div
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-once="true"
            className="absolute top-20 right-10 sm:right-24 sm:top-28 md:top-36 md:right-32 lg:top-32 lg:right-16 floating"
          ></div>
        </div>
      </div>
      <div className="relative z-40 text-white -mt-14 sm:-mt-24 lg:-mt-36">
        <svg
          className="xl:h-40 xl:w-full"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
            fill="currentColor"
          ></path>
        </svg>
        <div className="w-full h-20 -mt-px bg-white"></div>
      </div>
    </div>
  );
};

export default StartPage;
