import React from "react";

import SmallBoxInfoLeft from "../tools/SmallBoxInfoLeft";
import SmallBoxInfoRight from "../tools/SmallBoxInfoRight";
import SmallLinks from "../tools/SmallLinks";
import VideoBox from "../tools/VideoBox";
import CrmLinks from "../crm/CrmLinks";
import SodLinks from "./SodLinks";

const SodFlowProces = ({ fullInfo = false, links = false, without }) => {
  return (
    <div  className="containerMontemoro">
      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div data-aos="fade-right" className="relative sm:w-1/2">
          <div className="absolute z-0 w-12 h-12 bg-yellow-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
            Proces obiegu dokumentu
          </h1>
        </div>
      </div>

      <div
        data-aos="zoom-in-up"
        className="flex flex-col my-14 lg:flex-row lg:space-x-20"
      >
        <div className="lg:w-6/12">
          <SmallBoxInfoLeft
            h1="Mając wprowadzone wszystkie informacje o których była mowa w poprzednich krokach, możemy przystąpić do tworzenia nowego obiegu. "
            img="img/sod/new-flow.png"
          />
          <SmallLinks
            link="sod-proces-obiegu"
            title="Jak założyć przykładowy obieg dla naszej Faktury Zakupu zobaczysz na filmie"
            seeMore={true}
            show={!fullInfo}
          />
        </div>

        <div className="flex flex-col justify-between mt-12 space-y-5 lg:w-7/12 lg:space-y-0 lg:mt-0">
          <SmallBoxInfoRight
            img="img/sod/flow-start.png"
            h1="W przypadku tej Faktury Zakupu w pierwszym kroku jest departament ADM. Oznacza to, że każda osoba z tej grupy może rozpocząć obieg tego typu dokumentu."
          />

          <SmallBoxInfoRight
            img="img/sod/flow-case-message.png"
            h1="Pojedynczy obieg dokumentu składa się z informacji o sprawie, które wprowadzamy na podstawie zdefiniowanych pól oraz szeregu wiadomości ze szczegółowymi informacjami jak przebiega obieg."
            // p="Więcej o słownikach w dalszej częsci opisu."
          />

          <SmallBoxInfoRight
            img="img/sod/form-file.png"
            h1="Po założeniu sprawy, dodajemy następnie wiadomośc do której dodajemy opcjonalnie załącznik (lub załączniki) i szereg informacji takich jak nazwę zdarzenia i ew. opis"
          />
        </div>
      </div>
      {fullInfo && (
        <VideoBox
          h1="Zobacz film w jaki przeprowadziliśmy obieg tej Faktury Zakupu:"
          video="sod/sod-flow"
        />
      )}
      {links && <SodLinks without={without} />}
    </div>
  );
};

export default SodFlowProces;
