import React  from 'react';

const Quizes = () => {
  return (
    <div className="containerMontemoro">
    <div className="container max-w-screen-xl px-4 mx-auto overflow-hidden text-gray-700 lg:px-8">
      <div className="flex flex-col-reverse items-center mt-20 md:flex-row md:space-x-10">
        <div data-aos="fade-right" className="md:w-6/12">
          <img className="md:w-11/12" src="img/true-false.png" alt="quizes" />
        </div>
        <div
          data-aos="fade-left"
          className="md:w-6/12 md:transform md:-translate-y-20"
        >
          <h1 className="text-3xl font-semibold text-darken lg:pr-64">
            Assessments, <span className="text-yellow-500">Quizzes</span>, Tests
          </h1>
          <p className="my-5 text-gray-500 lg:pr-52">
            Easily launch live assignments, quizzes, and tests. Student results
            are automatically entered in the online gradebook.
          </p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Quizes;
