import React  from 'react';

import SmallBoxInfoLeft from "../tools/SmallBoxInfoLeft";
import SmallBoxInfoRight from "../tools/SmallBoxInfoRight";
import SmallLinks from "../tools/SmallLinks";
import VideoBox from "../tools/VideoBox";
import CrmLinks from "./CrmLinks";

const CrmProjectEdit = ({ fullInfo = false, links = false, without }) => {
  return (
    <div className="containerMontemoro">
      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div data-aos="fade-right" className="relative sm:w-1/2">
          <div className="absolute z-0 w-12 h-12 bg-yellow-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
            Edycja projektu
          </h1>
        </div>
      </div>

      <div
        data-aos="zoom-in-up"
        className="flex flex-col my-14 lg:flex-row lg:space-x-20"
      >
        <div className="lg:w-6/12">
          <SmallBoxInfoLeft
            h1="Podczas edycji projektu, opróch podstawowych parametrów, mamy także możliwość wybrania takich słownikowych parametrów jak: Sektory rynku, Producenci, Grupy produktowe"
            img="img/crm/project-edit1.png"
          />
            <SmallLinks
            link="crm-edycja-projektu"
            title = "Zobacz więcej o edycji projektu"
            seeMore={true}
            show = {!fullInfo}
          />
        </div>

        <div className="flex flex-col justify-between mt-12 space-y-5 lg:w-6/12 lg:space-y-0 lg:mt-0">
          <SmallBoxInfoRight
            img="img/crm/dictionaries.png"
            h1="Słowniki takie jak m.in. Sektory rynku, Producenci, Grupy produktowe modyfikujemy w sekcji słowników"
          />

          <SmallBoxInfoRight
            img="img/crm/user-rights.png"
            // h1="Przykład sortowania malejącego lub rosnącego"
            info_name="Uwaga"
            info="Możliwość edycji zależy oczywiście od zdefiniowanych uprawnień użytkownika"
          />
        </div>
      </div>
      {fullInfo && (
        <VideoBox h1="Zobacz film z edycji projektu" video="project-edit" />
      )}
      {links && <CrmLinks without={without} />}
    </div>
  );
};

export default CrmProjectEdit;
