import React  from 'react';

import SmallBoxInfoLeft from "../tools/SmallBoxInfoLeft";
import SmallBoxInfoRight from "../tools/SmallBoxInfoRight";
import SmallLinks from "../tools/SmallLinks";
import VideoBox from "../tools/VideoBox";
import CrmLinks from "./CrmLinks";

const CrmCalendar = ({ fullInfo = false, links = false, without }) => {

  return (
    <div className="containerMontemoro">
      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div data-aos="fade-right" className="relative sm:w-1/2">
          <div className="absolute z-0 w-12 h-12 bg-yellow-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
            Kalendarz
          </h1>
        </div>
      </div>

      <div
        data-aos="zoom-in-up"
        className="flex flex-col my-14 lg:flex-row lg:space-x-20"
      >
        <div className="lg:w-6/12">
          <SmallBoxInfoLeft
            h1="Program umożliwia obsługę kalendarza w powiązaniu z projektami. Na bazie tego Zarząd Firmy może np. sprawdzić aktywność poszczególnych handlowców."
            img="img/crm/calendar.png"
          />
           <SmallLinks
            link="crm-kalendarz"
            title="Zobacz więcej o kalendarzu"
            seeMore={true}
            show={!fullInfo}
          />
        </div>

        <div className="flex flex-col justify-between mt-12 space-y-5 lg:w-7/12 lg:space-y-0 lg:mt-0">
          <SmallBoxInfoRight
            img="img/crm/calendar-project.png"
            h1="Dla każdego projektu możemy definiować zdarzenia i umieszczać je w kalendarzu"
          />

          <SmallBoxInfoRight
            img="img/crm/calendar2.png"
            h1="Kalendarz ma możliwośc synchronizacji z kalendarzami np. Microsoft Office lub Google"
            
          />

    
        </div>
      </div>
      {fullInfo && <VideoBox h1="Zobacz film z możliwosciami kalendarza" video="calendar" />}
      {links && <CrmLinks without={without} />}
    </div>
  );
};

export default CrmCalendar;
