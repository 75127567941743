import React  from 'react';

const SodIntro = () => {
  return (
    <div className="containerMontemoro">
      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div data-aos="fade-right" className="relative sm:w-1/2">
          <div className="absolute z-0 w-12 h-12 bg-orange-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
          Oprogramowanie <span className="text-yellow-500">CRM </span>(Customer
        Relationship Management) - zarządza relacjami z Klientami, automatyzuje
        i wspomaga procesy w przedsiębiorstwie w zakresie pozyskania i
        utrzymania klienta.
          </h1>
          <p className="py-5 lg:pr-32">
          Program ten umożliwia zarządzanie projektami w powiązaniu z planowaniem przychodów i ich realizację. <br/>Umoźliwia śledzenie aktywności i wyniki handlowców.<br/>
        Zapewnia szczegółową analizę finansową prowadzonych projektów.<br/> Umożliwia planowanie i sprawdzanie realizacji działań finansowych firmy.<br/>
        Poniżej przedstawiamy możliwości i opis działania  programu.<br/><br/> Zachęcamy do zapoznania się z jego funkcjami.
          </p>
          {/* <a href="#" className="underline">
            Learn More
          </a> */}
        </div>
        <div data-aos="fade-left" className="relative mt-10 sm:w-1/2 sm:mt-0">
          <div className="absolute z-0 w-24 h-24 rounded-lg floating -top-3 -left-3 bg-[#23BDEE]"></div>
          <img
            className="relative z-40 rounded-xl"
            src="img/crm/crm.png"
            alt=""
          />
          
          <div className="absolute z-10 w-40 h-40 bg-yellow-500 rounded-lg floating -bottom-3 -right-3"></div>
        </div>
      </div>
    </div>
  );
};

export default SodIntro;
