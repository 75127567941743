import React from "react";

import { MdSportsTennis } from "react-icons/md";
import { FcWorkflow } from "react-icons/fc";
import { IoIosPeople } from "react-icons/io";
import { MdOutlineSolarPower } from "react-icons/md";
import { MdOutlineHomeWork } from "react-icons/md";
import { FaPeopleArrows } from "react-icons/fa";

const Software2 = () => {
  return (
    <div id="software2" className="pb-10 mb-4 containerMontemoro">
      <div data-aos="flip-up" className="max-w-xl mx-auto my-20 text-center">
        <h1 className="my-3 text-2xl font-bold text-darken">
          Montemoro <span className="textOrange"> - zobacz</span>
        </h1>
        <p className="leading-relaxed text-gray-500">
          nasze pozostałe programy o których napiszemy więcej wkrótce
        </p>
      </div>

      <div className="grid mt-20 md:grid-cols-4 gap-14 md:gap-5">
        <div
          data-aos="fade-right"
          className="p-6 text-center bg-white shadow-xl rounded-xl"
        >
          <div className="flex items-center justify-center w-16 h-16 mx-auto transform -translate-y-12 rounded-full shadow-lg bg-[#27b93f]">
            <MdSportsTennis className="text-3xl text-white animate-pulse" />
          </div>
          <h1 className="pt-3 mb-3 text-xl font-medium lg:px-14 text-darken lg:h-14">
            Obsługa Lig Sportowych
          </h1>
          <p className="px-4 text-gray-500">
            Oprogramowanie przeznaczone dla klubów sportowych umożliwiające
            obsługę sportowych lig i turniejów.
          </p>
        </div>
        <div
          data-aos="fade-up"
          className="p-6 text-center bg-white shadow-xl rounded-xl"
        >
          <div className="flex items-center justify-center w-16 h-16 mx-auto transform -translate-y-12 rounded-full shadow-lg bg-[#f88316]">
            <MdOutlineSolarPower className="text-3xl text-white" />
          </div>
          <h1 className="mb-3 text-xl font-medium lg:px-14 text-darken">
            System obsługi fotowoltaiki
          </h1>
          <p className="px-4 text-gray-500">
            Program umożliwiający realizację całego procesu obsługi fotowoltaiki
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="150"
          className="p-6 text-center bg-white shadow-xl rounded-xl"
        >
          <div className="flex items-center justify-center w-16 h-16 mx-auto transform -translate-y-12 rounded-full shadow-lg bg-[#56686d]">
            <MdOutlineHomeWork className="text-3xl text-white animate-pulse" />
          </div>
          <h1 className="mb-3 text-xl font-medium lg:px-14 text-darken">
            Obsługa biur nieruchomości
          </h1>
          <p className="px-4 text-gray-500">Pogram dla biur nieruchomości</p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="300"
          className="p-6 text-center bg-white shadow-xl rounded-xl"
        >
          <div className="flex items-center justify-center w-16 h-16 mx-auto transform -translate-y-12 rounded-full shadow-lg bg-[#29B9E7]">
            <IoIosPeople className="text-3xl text-white" />
          </div>
          <h1 className="pt-3 mb-3 text-xl font-medium lg:px-14 text-darken lg:h-14">
            Oprogramowanie dedykowane
          </h1>
          <p className="px-4 text-gray-500">
            Tworzymy także oprogramowanie na życzenie Klienta.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Software2;
