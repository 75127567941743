import React  from 'react';

const OneOnOne = () => {
  return (
    <div className="containerMontemoro">
      <div className="flex flex-col-reverse items-center mt-24 md:flex-row md:space-x-10">
			<div data-aos="fade-right" className="md:w-7/12">
				<img className="md:w-11/12" src="img/discussion.png" />
			</div>
			<div data-aos="fade-left" className="md:w-5/12 md:transform md:-translate-y-6">
				<h1 className="text-3xl font-semibold text-darken lg:pr-64">One-on-One <span className="text-yellow-500">Discussions</span></h1>
				<p className="my-5 text-gray-500 lg:pr-24">Teachers and teacher assistants can talk with students privately without leaving the Zoom environment.</p>
			</div>
		</div>
    <button
        data-aos="flip-up"
        className="block px-5 py-3 mx-auto font-medium text-yellow-500 transition duration-300 ease-in-out transform border border-yellow-500 rounded-full my-14 focus:outline-none hover:scale-110"
      >
        See more features
      </button>

    </div>
  );
};

export default OneOnOne;
