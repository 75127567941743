import React from "react";
import { IoIosPeople } from "react-icons/io";
import { FcAssistant } from "react-icons/fc";
import { FcFlowChart } from "react-icons/fc";
import { FcRating } from "react-icons/fc";
const Offer = () => {
  return (
    <div className="containerMontemoro">
      <div className="items-start mt-40 md:flex md:space-x-10">
        <div data-aos="fade-down" className="relative md:w-6/12">
          <div className="absolute z-20 w-32 h-32 rounded-full left-4 -top-12 animate-pulse bg-[#1b2841]"></div>
          <div className="absolute z-0 w-5 h-5 rounded-full left-36 -top-12 animate-ping  bg-[#33D9EF] "></div>
          <img
            className="relative z-10 rounded-2xl floating"
            src="img/offer.jpg"
            alt="Oferta na programy CRM i SOD"
          />
          <div className="absolute z-20 rounded-full w-36 h-36 right-16 -bottom-1 animate-pulse  bg-[#5B61EB]"></div>
          <div className="absolute z-10 w-5 h-5 rounded-full right-52 bottom-1 animate-ping  bg-[#F56666]"></div>
        </div>
        <div
          data-aos="fade-down"
          className="mt-20 text-gray-500 md:w-6/12 md:mt-0"
        >
          <h1 className="text-2xl font-semibold text-darken lg:pr-40">
            Oferta<span className="text-yellow-500"> !!!</span>
          </h1>
          <div className="flex items-center my-5 space-x-5">
            <div className="flex items-center justify-center flex-shrink p-3 bg-white rounded-full shadow-lg">
              <FcAssistant className="text-xl" />
            </div>
            <p>
              Darmowa wersja demonstracyjna programów CRM i SOD przez okres
              1&#8209;ego miesiąca
            </p>
          </div>
          <div className="flex items-center my-5 space-x-5">
            <div className="flex items-center justify-center flex-shrink p-3 bg-white rounded-full shadow-lg">
              <IoIosPeople className="text-xl" />
            </div>
            <p>Koszt programu <a href="/crm">CRM</a> - 30 zł netto za każdego użytkownika / miesiąc</p>
          </div>
          <div className="flex items-center my-5 space-x-5">
            <div className="flex items-center justify-center flex-shrink p-3 bg-white rounded-full shadow-lg">
              <FcFlowChart className="text-xl" />
            </div>
            <p>Koszt programu <a href="/crm">SOD</a> - 30 zł netto za każdego użytkownika / miesiąc</p>
          </div>
          <div className="flex items-center my-5 space-x-5">
            <div className="flex items-center justify-center flex-shrink p-3 bg-white rounded-full shadow-lg">
              <FcRating className="text-xl" />
            </div>
            <p>Koszt programu CRM+SOD - 45 zł netto za każdego użytkownika / miesiąc</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
