import React  from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';

const SmallBoxInfoRight = ({ info_name, info, img, alt, h1, p }) => {
  return (
    <div className="flex space-x-5">
      <div className="w-6/12 p-4 my-3 bg-[#f8faf4] shadow-2xl rounded-2xl">
        <h1 className="my-2 text-sm text-gray-800 sm:text-lg">{h1}</h1>

        {info_name && (
          <>
            <span className="relative px-4 py-1 text-sm rounded-full bg-y-llow-300 top-2 left-2 text-darken ">
              {info_name}
            </span>
            <p className="my-2 text-gray-500 text-md sm:my-4 sm:text-md">
              {info}
            </p>
          </>
        )}
        <p className="my-2 text-xs text-gray-500 sm:my-4 sm:text-md">{p}</p>
      </div>
      <div className="w-6/12 p-4 mb-3 shadow-xl rounded-xl ">
        <div className="relative">
        <PhotoProvider  bannerVisible={true} >
        <PhotoView src={img}>
          <img
            className="w-full p-1 scale-100 border rounded-xl cursor-zoom-in"
            src={img}
            alt={alt}
            style={{ objectFit: "cover" }}
          />
          </PhotoView>
          </PhotoProvider>

          {/* {isInfo && <span className="absolute hidden px-4 py-px text-sm font-semibold bg-yellow-300 rounded-full bottom-2 right-2 text-darken sm:block">
            PRESS RELEASE
          </span>} */}
        </div>
      </div>
    </div>
  );
};

export default SmallBoxInfoRight;
