import React  from 'react';
import { GiMoneyStack } from "react-icons/gi";
import { TbZoomMoney } from "react-icons/tb";
import { GiCash } from "react-icons/gi";
import VideoBox from "../tools/VideoBox";

import CrmLinks from "./CrmLinks";
import SmallLinks from "../tools/SmallLinks";

const CrmFinansialAnalysis = ({ fullInfo = false, links = false, without }) => {
  return (
    <div className="containerMontemoro">
      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div
          // data-aos="fade-right"
          className="relative p-5 m-5 shadow-2xl sm:w-1/2 rounded-2xl"
        >
          <div className="absolute z-0 w-12 h-12 bg-yellow-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
            Analiza finansowa
          </h1>
        </div>
      </div>
      <div className="items-start mt-10 md:flex md:space-x-10">
        <div data-aos="fade-down" className="relative md:w-7/12">
          <h1 className="font-semibold text-md">
            Najważniejszym modułem CRM jest analiza finansowa, dzięki której
            możemy planować przychody firmy i sledzić realizację tych przychodów
          </h1>
          <div className="absolute z-10 w-5 h-5 rounded-full right-10 top-20 animate-ping  bg-[#33D9EF] "></div>
          <div className="absolute z-10 w-32 h-32 rounded-full right-20 top-10 animate-pulse bg-[#33EFA0]"></div>
          <div className="absolute z-10 rounded-full w-36 h-36 right-0 -bottom-6 animate-pulse  bg-[#5B61EB]"></div>
          <div className="absolute z-20 w-5 h-5 rounded-full right-12 bottom-1 animate-ping  bg-[#F56666]"></div>
          <img
            className="relative z-5 floating"
            src="img/crm/finansial5.png"
            alt="crm analiza finansowa"
          />
          <SmallLinks
            link="crm-analiza-finansowa"
            title="Zobacz więcej o analizie finansowej"
            seeMore={true}
            show={!fullInfo}
          />
        </div>
        <div
          data-aos="fade-down"
          className="mt-20 text-gray-500 md:w-5/12 md:mt-0"
        >
          <h1 className="text-2xl font-semibold text-darken">
            Najważniejsze cechy:
          </h1>
          <div className="flex items-center my-5 space-x-5">
            <div className="flex items-center justify-center flex-shrink p-3 bg-white rounded-full shadow-lg">
              <TbZoomMoney className="text-xl text-blue-500" />
            </div>
            <p>Przychody planowane w rozbiciu na poszczególne miesiące</p>
          </div>

          <div className="flex items-center my-5 space-x-5">
            <div className="flex items-center justify-center flex-shrink p-3 bg-white rounded-full shadow-lg">
              <GiMoneyStack className="text-xl text-green-500" />
            </div>
            <p>
              Przychody rzeczywiste z rozbiciem na: przychody, marżę procentową
              i marżę złotówkową
            </p>
          </div>

          <div className="flex items-center my-5 space-x-5">
            <div className="flex items-center justify-center flex-shrink p-3 bg-white rounded-full shadow-lg">
              <GiCash className="text-xl text-orange-500" />
            </div>
            <p>Marże ważone w rozbiciu na poszczególne miesiące</p>
          </div>

          <div className="flex items-center my-5 space-x-5">
            <div className="flex items-center justify-center flex-shrink p-3 bg-white rounded-full shadow-lg">
              <TbZoomMoney className="text-xl text-[#21443a]" />
            </div>
            <p>
              Sumowane wartości Realizacji i planu (plan ważony) z rozbiciem na:
              przychody, marżę procentową i marżę złotówkową
            </p>
          </div>

          <div className="flex items-center my-5 space-x-5">
            <div className="flex items-center justify-center flex-shrink p-3 bg-white rounded-full shadow-lg">
              <GiMoneyStack className="text-xl text-[#160e22]" />
            </div>
            <p>
              Sumowane wartości Realizacji i planu (plan ważony) z rozbiciem na:
              przychody, marżę procentową i marżę złotówkową
            </p>
          </div>
        </div>
      </div>
      {fullInfo && (
        <VideoBox
          h1="Zobacz film z przeglądania analizy finansowej"
          video="crm/finansial"
        />
      )}
      {links && <CrmLinks without={without} />}
    </div>
  );
};

export default CrmFinansialAnalysis;
