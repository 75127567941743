import React from "react";

import { MdSportsTennis } from "react-icons/md";
import { FcWorkflow } from "react-icons/fc";
import { IoIosPeople } from "react-icons/io";
import { MdOutlineSolarPower } from "react-icons/md";
import { MdOutlineHomeWork } from "react-icons/md";
import { FaPeopleArrows } from "react-icons/fa";
import { FcFlashOn } from "react-icons/fc";

const Software1 = ({without}) => {

  let showOffer = without === 'offer' ? false : true
  return (
    <div id="software1" className={`pb-10 mb-4 ${showOffer ? "containerMontemoro" : "containerMontemoroNarrow"}`}>
      <div data-aos="flip-up" className="max-w-xl mx-auto my-20 text-center">
        <h1 className="my-3 text-2xl font-bold text-darken">
          Montemoro <span className="textOrange">- oprogramowanie</span>
        </h1>
        <p className="leading-relaxed text-gray-500">
          Jesteśmy producentem oprogramowania dla biznesu.
        </p>
      </div>

      <div className={`grid mt-20 ${showOffer ? "md:grid-cols-3 md:gap-5" : "md:grid-cols-2 md:gap-10"} gap-14 `}>
        <a href="/crm" className=" rounded-xl">
          <div
            data-aos="fade-right"
            data-aos-delay="250"
            className="p-6 shadow-xl hover:bg-orange-100 text-center rounded-xl bg-[#f6f8ec]"
          >
            <div className="flex items-center justify-center w-16 h-16 mx-auto transform -translate-y-12 rounded-full shadow-lg bg-[#636eac]">
              {/* <HiOutlineClipboardDocumentList className="text-3xl text-white animate-pulse" /> */}
              <FaPeopleArrows className="text-3xl text-white animate-pulse" />
            </div>
            <h1 className="mb-3 text-xl font-medium lg:px-14 text-darken ">
              CRM
            </h1>
            <p className="px-4 ">
              CRM (Customer Relationship Management) - zarządza relacjami z
              Klientami, automatyzuje i wspomaga procesy na styku
              klient-organizacja w zakresie pozyskania, utrzymania klienta
            </p>
          </div>
        </a>

        <a href="/sod" className=" rounded-xl">
          <div
            data-aos="fade-down"
            data-aos-delay="350"
            className="p-6 shadow-xl hover:bg-orange-100 text-center rounded-xl bg-[#f2faf4]"
          >
            <div className="flex items-center justify-center w-16 h-16 mx-auto transform -translate-y-12 rounded-full shadow-lg bg-[#e5ecba]">
              <FcWorkflow className="text-3xl text-white animate-pulse" />
            </div>
            <h1 className="mb-3 font-medium text-md lg:px-14 text-darken">
              System Obiegu Dokumentów
            </h1>
            <p className="px-4 pb-12 text-gray-500">
              Zapewnia sprawny elektroniczny obieg dokumentów poprzez ich
              uporządkowany przepływ.
            </p>
          </div>
        </a>

        {showOffer && <a href="/oferta" className=" rounded-xl">
          <div
            data-aos="fade-left"
            data-aos-delay="600"
            className="p-6 shadow-xl hover:bg-orange-100 text-center rounded-xl bg-[#faf2f3]"
          >
            <div className="flex items-center justify-center w-16 h-16 mx-auto transform -translate-y-12 rounded-full shadow-lg bg-[#e5ecba]">
              <FcFlashOn className="text-3xl text-white animate-pulse" />
            </div>
            <h1 className="text-xl font-medium lg:px-10 text-darken lg:h-10">
              Oferta !!!
            </h1>
            <p className="px-2 pb-16 text-gray-500">
              Zobacz ofertę na nasze programy:
              <br />
              CRM + SOD
            </p>
          </div>
        </a>}
      </div>
    </div>
  );
};

export default Software1;
