import { FcAddImage } from "react-icons/fc";
import { FaPeopleArrows } from "react-icons/fa";
import { FcBinoculars } from "react-icons/fc";
import { FcEditImage } from "react-icons/fc";
import { FcAreaChart } from "react-icons/fc";
import { FcEngineering } from "react-icons/fc";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { TbZoomMoney } from "react-icons/tb";
import { IoPeople } from "react-icons/io5";
import { FcFlowChart } from "react-icons/fc";
import { GoWorkflow } from "react-icons/go";
import { FcDepartment } from "react-icons/fc";
import { FcAddDatabase } from "react-icons/fc";
import { FcWorkflow } from "react-icons/fc";
import { TiFlowChildren } from "react-icons/ti";
import { FcSerialTasks } from "react-icons/fc";

const SeoTab = [
  {
    key: "home",
    title: " Montemoro - Oprogramowanie dla biznesu",
    desc: "Najlepsze programy na rynku - CRM, SOD",
  },
  {
    key: "software",
    title: "Montemoro - Oprogramowanie dla firm - CRM, SOD",
    desc: "Najlepsze programy na rynku - Customer Relatiship Management , Elektroniczny System Obiegu Dokumentów",
  },
  {
    key: "o-nas",
    title: "Montemoro - O nas",
    desc: "Zobacz informacje o nas",
  },
  {
    key: "kontakt",
    title: "Montemoro - producent oprogramowania",
    desc: "Zobacz nasz system CRM",
  },
  {
    key: "crm",
    cat: "crm",
    label: "Opis programu CRM",
    icon: <FaPeopleArrows />,
    title: "Prawdopodobnie najlepszy i tani system CRM",
    desc: "CRM - informacje o programie",
  },
  {
    key: "crm-konfiguracja-programu",
    cat: "crm",
    label: "Konfiguracja programu CRM",
    icon: <GoWorkflow />,
    title: "CRM - prosta konfiguracja programu",
    desc: "CRM - zobacz jak skonfigurować program",
  },
  {
    key: "crm-dodawanie-projektu",
    cat: "crm",
    label: "Dodawanie projektu",
    icon: <FcAddImage />,
    title: "CRM - dodawanie projektu",
    desc: "CRM - Dodawanie projektu do systemu",
  },
  {
    key: "crm-edycja-projektu",
    cat: "crm",
    label: "Edycja projektu",
    icon: <FcEditImage />,
    title: "CRM - edycja projektu",
    desc: "CRM - zaawansowane możliwości edycyjne projektu",
  },
  {
    key: "crm-przegladanie-projektow",
    cat: "crm",
    label: "Przeglądanie projektów",
    icon: <FcBinoculars />,
    title: "CRM - przeglądanie projektów",
    desc: "CRM - wygodne przeglądanie projektów",
  },
  {
    key: "crm-inne-czynnosci",
    cat: "crm",
    label: "Projekty - Inne czynności",
    icon: <FcEngineering />,
    title: "CRM - pozostałe czynności",
    desc: "CRM - inne czynności związane z projektami",
  },
  {
    key: "crm-harmonogram-przychodow",
    cat: "crm",
    label: "Harmonogram przychodów",
    icon: <FcAreaChart />,
    title: "CRM - harmonogram przychodów",
    desc: "CRM - Szczegółowy Harmonogram przychodów",
  },
  {
    key: "crm-kalendarz",
    cat: "crm",
    label: "Obsługa kalendarza",
    icon: <BsFillCalendarCheckFill />,
    title: "CRM - kalendarz",
    desc: "CRM - rozbudowany kalendarz",
  },
  {
    key: "crm-analiza-finansowa",
    cat: "crm",
    label: "Analiza finansowa",
    icon: <TbZoomMoney />,
    title: "CRM - Analiza finansowa",
    desc: "CRM - Szczegółowa analiza finansowa",
  },
  {
    key: "crm-zarzadzanie-uzytkownikami",
    cat: "crm",
    label: "Zarządzanie użytkownikami",
    icon: <IoPeople />,
    title: "CRM - Zarządzanie użytkownikami",
    desc: "CRM -  Zarządzanie użytkownikami i Klientami",
  },
  {
    key: "sod",
    cat: "sod",
    label: "Opis programu SOD",
    icon: <FcFlowChart />,
    title: "Prawdopodobnie najlepszy i tani system obiegu dokumentów",
    desc: "System Obiegu Dokumentów - informacje o programie",
  },
  {
    key: "sod-konfiguracja-programu",
    cat: "sod",
    label: "Konfiguracja programu SOD",
    icon: <GoWorkflow />,
    title: "System Obiegu Dokumentów - prosta konfiguracja programu",
    desc: "SOD - zobacz jak skonfigurować program obiegu dokumentów",
  },
  {
    key: "sod-zarzadzanie-uzytkownikami",
    cat: "sod",
    label: "Zarządzanie użytkownikami",
    icon: <IoPeople />,
    title: "SOD - Zarządzanie użytkownikami",
    desc: "SOD -  Zarządzanie użytkownikami i Klientami",
  },
  {
    key: "sod-konfiguracja-departamentu",
    cat: "sod",
    label: "Konfiguracja departamentów",
    icon: <FcDepartment />,
    title: "System Obiegu Dokumentów - konfiguracja departamentów firmy",
    desc: "SOD - zobacz jak skonfigurować departamenty firmy",
  },
  {
    key: "sod-konfiguracja-obiegu",
    cat: "sod",
    label: "Konfiguracja obiegu",
    icon: <GoWorkflow />,
    title: "System Obiegu Dokumentów - konfiguracja obiegu",
    desc: "SOD - zobacz jak skonfigurować proces obiegu dokumentów",
  },
  
  {
    key: "sod-konfiguracja-pol-formularza",
    cat: "sod",
    label: "Konfiguracja pól formularza",
    icon: <FcAddDatabase />,
    title: "System Obiegu Dokumentów - konfiguracja pól formularza",
    desc: "SOD - zobacz jak skonfigurować pola formularza",
  },
  {
    key: "sod-proces-obiegu",
    cat: "sod",
    label: "Proces obiegu",
    icon: <FcSerialTasks />,
    title: "System Obiegu Dokumentów - proces obiegu",
    desc: "SOD - zobacz jak przeprowadzić proces obiegu dokumentu",
  },
];

export { SeoTab };
