import React  from 'react';

const Footer = (props) => {

  return (
    <footer id="contact" className="mt-10 bg-[#1b2841]">
      <div className="max-w-lg mx-auto">
        <div className="flex items-center justify-center px-20 py-12 text-white sm:px-36">
          <div className="relative">
            <h1 className="relative z-50 pr-5 text-xl font-bold">Montemoro</h1>
            <img src="img/montemoro2.png" alt="Motemoro" />
          </div>
          <span className="py-2 pl-5 text-sm font-semibold border-l border-gray-500">
            Producent oprogramowania
          </span>
        </div>
        {/* <div className="pt-5 pb-16 text-center">
          <label className="font-semibold text-gray-300">
            Subscribe to get our Newsletter
          </label>
          <div className="flex flex-col justify-center px-5 mt-3 space-y-3 sm:px-0 sm:flex-row sm:space-x-3 sm:space-y-0">
            <input
              type="email"
              placeholder="Your Email"
              className="py-2 pl-5 bg-transparent border border-gray-400 rounded-full"
            />
            <button
              type="submit"
              className="w-40 px-5 py-2 mx-auto font-semibold text-white rounded-full sm:w-auto sm:mx-0 bg-gradient-to-r from-[#545ae7] to-[#393fcf] from-20% to-80% "
              
            >
              Subscribe
            </button>
          </div>
        </div> */}

        <div className="text-center text-white">
          <div className="py-3 tracking-wide">
            <p>
              telefon: <span className="font-semibold">+48 694 230 722</span>
            </p>
            <p>
              e-mail: <span className="font-semibold">biuro@montemoro.pl</span>
            </p>
          </div>
        </div>

        <div className="flex items-center justify-center pb-10 text-sm text-gray-400 ">
          <p>
            <a href="/terms" className="pl-3" >
              Terms & Conditions 
            </a>
          </p>
          <p className="my-3 text-gray-400 b-10 ptext-sm">
            &copy; 2023 Montemoro
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
