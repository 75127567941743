import React from "react";

import SmallBoxInfoLeft from "../tools/SmallBoxInfoLeft";
import SmallBoxInfoRight from "../tools/SmallBoxInfoRight";
import SmallLinks from "../tools/SmallLinks";
import VideoBox from "../tools/VideoBox";
import CrmLinks from "../crm/CrmLinks";
import SodLinks from "./SodLinks";

const SodDeptConfig = ({ fullInfo = false, links = false, without }) => {
  return (
    <div id="deptConfig" className="containerMontemoro">
      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div data-aos="fade-right" className="relative sm:w-1/2">
          <div className="absolute z-0 w-12 h-12 bg-yellow-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
            Konfiguracja departamentów Firmy
          </h1>
        </div>
      </div>

      <div
        data-aos="zoom-in-up"
        className="flex flex-col my-14 lg:flex-row lg:space-x-20"
      >
        <div className="lg:w-6/12">
          <SmallBoxInfoLeft
            h1="Zaczęliśmy naszą prezentację od stworzenia przykładowego obiegu. Aby to zrobić wcześniej musimy zdefiniować Departamenty (działy) i przypisać do nich użytkowników"
            img="img/sod/dept-config.png"
          />
          <SmallLinks
            link="sod-konfiguracja-departamentu"
            title="Zobacz jak zdefiniować departament i przypisać do niego użytkowników."
            seeMore={true}
            show={!fullInfo}
          />
        </div>

        <div className="flex flex-col justify-between mt-12 space-y-5 lg:w-7/12 lg:space-y-0 lg:mt-0">
          <SmallBoxInfoRight
            img="img/sod/flow-tool.png"
            h1="Jeżeli chcemy bezpośrednio przesyłać do użytkownika to wystarczy to możemy to zrobić definiując jednoosobowy departament. W tym przypadku JAMES"
          />

          <SmallBoxInfoRight
            img="img/sod/user-role.png"
            h1="Użytkownika przypisujemy do odpowiedniego departamentu. Jeżeli używamy programów CRM + SOD konfigurację uprawnień robimy w jednym miejscu."
            // p="Więcej o słownikach w dalszej częsci opisu."
          />

          <SmallBoxInfoRight
            img="img/sod/dept-user.png"
            h1="Kierując obieg do właściwego departamentu umożliwiamy każdemu użytkownikowi z tej grupy pracę nad obiegiem dokumentu."
          />
        </div>
      </div>
      {fullInfo && (
        <VideoBox
          h1="Zobacz film w jaki sposób skonfigurowaliśmy departament GK (Główny Księgowy):"
          video="sod/sod-dept-config"
        />
      )}
      {links && <SodLinks without={without} />}
    </div>
  );
};

export default SodDeptConfig;
