import React  from 'react';

import { useState } from "react";
import { BsFillArrowUpCircleFill } from "react-icons/bs";




const ArrowUp = (props) => {
  const [isScrolled, setIsScrolled] = useState(false)
  
  const handleScroll = (event) => {
    if (window.scrollY > 200) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  };
  
  window.addEventListener('scroll', handleScroll);
  
  return (
    <div className="fixed right-10 bottom-10">
      <a href="#home">
        <BsFillArrowUpCircleFill className={`textOrange text-5xl animate-bounce opacity-50 ${isScrolled ? "block" : "hidden"}`} />
        </a>
    </div>
  );
};

export default ArrowUp;
