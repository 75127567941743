import React  from 'react';

const Integrations = () => {
  return (
    <div className="containerMontemoro">
     <div className="flex flex-col items-start mt-24 md:flex-row md:space-x-10">
			<div data-aos="zoom-in-right" className="md:w-6/12">
				<img className="mx-auto md:w-8/12" src="img/integrations.png" />
			</div>
			<div data-aos="zoom-in-left" className="md:w-6/12">
				<div className="flex items-center mb-5 space-x-20">
					<span className="absolute border border-gray-300 w-14"></span>
					<h1 className="text-sm tracking-widest text-gray-400">INTEGRATIONS</h1>
				</div>
				<h1 className="text-2xl font-semibold text-darken lg:pr-40">200+ educational tools and platform <span className="text-yellow-500">integrations</span></h1>
				<p className="my-5 text-gray-500 lg:pr-20">Schoology has every tool your classroom needs and comes pre-integrated with more than 200+ tools, student information systems (SIS), and education platforms.</p>
				<button className="px-5 py-3 my-4 font-medium text-yellow-500 transition duration-300 ease-in-out transform border border-yellow-500 rounded-full focus:outline-none hover:scale-110">See All Integrations</button>
			</div>
		</div>
    </div>
  );
};

export default Integrations;
