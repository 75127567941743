import React  from 'react';

const SmallLinks = ({ icon, title, link, seeMore = false, show = true }) => {
  let isLink = link === "" ? false : true;

  let MyIcon = icon;

  if (!show) return;

  let content = (
    <div
      className={`flex items-center p-4 my-1 space-x-5 ${
        !isLink && `bg-[#0e2f5a] text-white opacity-75`
      } rounded-xl   ${isLink ? `hover:text-white hover:opacity-75 hover:bg-[#0e2f5a]`: ``}`}
    >
      {seeMore && <div className="text-md bounce">{title}...</div>}
      {!seeMore && (
        <>
          <div className="flex items-center justify-center flex-shrink p-3 bg-white rounded-full shadow-lg">
            <MyIcon className="text-xl text-blue-500" />
          </div>
          <p>{title}</p>
        </>
      )}
    </div>
  );

  if (isLink) return <a href={link}>{content}</a>;
  if (!isLink) return <>{content}</>;
};

export default SmallLinks;
