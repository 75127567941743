import React  from 'react';

import VideoBox from "../tools/VideoBox";

import CrmLinks from "./CrmLinks";
import SmallBoxInfoLeft from "../tools/SmallBoxInfoLeft";
import SmallBoxInfoRight from "../tools/SmallBoxInfoRight";
import SmallLinks from "../tools/SmallLinks";

const CrmUserManagement = ({ fullInfo = false, links = false, without }) => {
  return (
    <div id="userConfig" className="containerMontemoro">
      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div data-aos="fade-right" className="relative sm:w-1/2">
          <div className="absolute z-0 w-12 h-12 bg-yellow-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
            Konfiguracja użytkowników
          </h1>
        </div>
      </div>

      <div
        data-aos="zoom-in-up"
        className="flex flex-col my-14 lg:flex-row lg:space-x-20"
      >
        <div className="lg:w-6/12">
          <SmallBoxInfoLeft
            h1="Mając odpowiednie uprawnienia możemy administrować użytkownikami i nadawać im odpowiednie uprawnienia"
            img="img/crm/users.png"
          />
          <SmallLinks
            link="crm-zarzadzanie-uzytkownikami"
            title="Zobacz więcej o zarządzaniu użytkownikami i Klientami"
            seeMore={true}
            show={!fullInfo}
          />
        </div>

        <div className="flex flex-col justify-between mt-12 space-y-5 lg:w-7/12 lg:space-y-0 lg:mt-0">
          <SmallBoxInfoRight
            img="img/crm/users-edit.png"
            h1="Użytkownika podczas edycji przypisujemy do odpowiedniego działu."
          />

          <SmallBoxInfoRight
            img="img/crm/user-rights.png"
            h1="Użytkownikomi nadajemy odpowiednie uprawnienia"
          />
       
        </div>
      </div>

      {fullInfo && (
        <VideoBox
          h1="Zobacz film z zarządzania Użytkownikami i klientami"
          video="crm/user-management"
        />
      )}
      {links && <CrmLinks without={without} />}
    </div>
  );
};

export default CrmUserManagement;
