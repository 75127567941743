import { IoIosPeople } from "react-icons/io";

const SmallBox = () => {
  return (
    //     <a className="block rounded " href="/crm">
    // <div className="absolute w-40 bg-white rounded-2xl floating-4 top-10 right-6">
    //   <div className="">
    //     {/* <IoIosPeople className="text-5xl  hover:text-white text-[#498b70]" /> */}
    //     <img className="w-100 rounded-2xl" src="img/sod/sod-intro.png"></img>
    //   </div>
    //   <div className="absolute right-0 w-1/2 font-bold text-right bottom-3">SOD</div>
    // </div>
    // </a>
    <>
        <a className="block rounded " href="/crm">
        <div
          className="absolute flex p-3 bg-white rounded-lg hover:bg-[#f1d9d7]
          top-10 -left-6
          floating-4
          sm:top-32 sm:left-10
          md:top-40 md:left-16
          lg:-left-0 lg:top-20
          "
        >
          <div className="pr-2 text-xs ">
            Zobacz nasz <br />
            system <br /> CRM
          </div>
          <div className="w-1/2 p-0 m-0">
            <IoIosPeople className="text-5xl  hover:text-white text-[#498b70]" />
          </div>
        </div>
      </a>

  
      
      </>
  );
};

export default SmallBox;
