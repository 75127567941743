import React  from 'react';

const VideoBox = ({ video, h1, startTime = 10 }) => {
  // "#t=10,20"
  let startTimeString = "#t=" + startTime;

  let mp4 = "video/" + video + ".mp4" + startTimeString;
  let ogg = "video/" + video + ".ogg" + startTimeString;
  return (
    <>
      <h1 className="mx-0 mt-4 mb-2 text-sm font-semibold text-gray-800 sm:text-lg">
        {h1}
      </h1>
      <video className="border-2 w-100 rounded-2xl" controls>
        <source src={mp4} type="video/mp4" />
        <source src={ogg} type="video/ogg" />
        Your browser does not support the video tag.
      </video>
    </>
  );
};

export default VideoBox;
