import React  from 'react';

import SmallLinks from "../tools/SmallLinks";
import { FcFlowChart } from "react-icons/fc";
import { GoWorkflow } from "react-icons/go";
import { FcAddDatabase } from "react-icons/fc";
import { FcSerialTasks } from "react-icons/fc";
import { FcDepartment } from "react-icons/fc";
import { FcDataConfiguration } from "react-icons/fc";
import SeeMore from "../tools/SeeMore";

const SodLinks = ({ without, title }) => {
  let linkSodInfo =
    without === "SodInfo" ? "" : "/sod";

  let linkSodConfigFlow =
    without === "SodConfigFlow" ? "" : "/sod-konfiguracja-obiegu";

    let linkSodConfigDept =
    without === "SodConfigDept" ? "" : "/sod-konfiguracja-departamentu";
    
    let linkSodConfigForm =
    without === "SodConfigForm" ? "" : "/sod-konfiguracja-pol-formularza";
    
    let linkSodConfigProgram =
    without === "SodConfigProgram" ? "" : "/sod-konfiguracja-programu";
    
    
    let linkSodFlow =
    without === "SodFlow" ? "" : "/sod-proces-obiegu";
    
   

  return (
    <>
      <SeeMore title={title} />
      <div className="containerMontemoro">
        <div className="items-start mt-16 md:flex md:space-x-10">
          <div 
          // data-aos="fade-down"
           className="relative hidden border md:block mt-14 md:w-4/12">
            <div className="absolute z-0 w-32 h-32 rounded-full left-4 -top-12 animate-pulse bg-[#33EFA0]"></div>
            <div className="absolute z-10 w-5 h-5 rounded-full left-36 -top-12 animate-ping  bg-[#33D9EF] "></div>
            <div className="absolute z-10 rounded-full w-36 h-36 right-16 -bottom-6 animate-pulse  bg-[#5B61EB]"></div>
            <div className="absolute z-20 w-5 h-5 rounded-full right-52 bottom-1 animate-ping  bg-[#F56666]"></div>
            <img
              className="relative z-5 floating"
              src="img/crm/crm-intro.png"
              alt=""
            />
          </div>

          <div
            data-aos="fade-down"
            className="mt-20 text-gray-500 md:w-4/12 md:mt-0"
          >
          


  
          <SmallLinks
              icon={FcFlowChart}
              title="Opis programu SOD"
              link={linkSodInfo}
            />
            <SmallLinks
              icon={FcDataConfiguration}
              title="Konfiguracja programu"
              link={linkSodConfigProgram}
            />
          <SmallLinks
              icon={GoWorkflow}
              title="Konfiguracja obiegu"
              link={linkSodConfigFlow}
            />
           
    
          
          </div>

          <div
            data-aos="fade-down"
            className="mt-20 text-gray-500 md:w-4/12 md:mt-0"
          >
             <SmallLinks
              icon={FcDepartment}
              title="Konfiguracja departamentów"
              link={linkSodConfigDept}
            />
            <SmallLinks
              icon={FcAddDatabase}
              title="Konfiguracja pól formularza"
              link={linkSodConfigForm}
            />
<SmallLinks
              icon={FcSerialTasks}
              title="Proces obiegu"
              link={linkSodFlow}
            />




           

           
          </div>
        </div>
      </div>
    </>
  );
};

export default SodLinks;
