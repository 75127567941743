import React from "react";

import { BsFillCalendarCheckFill } from "react-icons/bs";
import { TbZoomMoney } from "react-icons/tb";
import { IoIosPeople } from "react-icons/io";
import SmallLinks from "../tools/SmallLinks";
import { FcAddImage } from "react-icons/fc";
import { FaPeopleArrows } from "react-icons/fa";
import { FcBinoculars } from "react-icons/fc";
import { FcEditImage } from "react-icons/fc";
import { FcAreaChart } from "react-icons/fc";
import { FcEngineering } from "react-icons/fc";
import SeeMore from "../tools/SeeMore";
import { SeoTab } from "../../config/Config";



const CrmLinks = ({ without, title }) => {
  let linkCrmInfo = without === "CrmInfo" ? "" : "/crm";
  let linkCrmProjectAdd =
    without === "CrmProjectAdd" ? "" : "/crm-dodawanie-projektu";
  let linkCrmProjectView =
    without === "CrmProjectView" ? "" : "/crm-przegladanie-projektow";
  let linkCrmProjectEdit =
    without === "CrmProjectEdit" ? "" : "/crm-edycja-projektu";
  let linkCrmProjectOthers =
    without === "CrmProjectOthers" ? "" : "/crm-inne-czynnosci";
  let linkCrmProjectSchedule =
    without === "CrmProjectSchedule" ? "" : "/crm-harmonogram-przychodow";
  let linkCrmCalendar = without === "CrmCalendar" ? "" : "/crm-kalendarz";
  let linkCrmFinansialAnalysis =
    without === "CrmFinansialAnalysis" ? "" : "/crm-analiza-finansowa";
  let linkCrmUserManagement =
    without === "CrmUserManagement" ? "" : "/crm-zarzadzanie-uzytkownikami";

   
    
  return (
    <>
      <SeeMore title={title} />
      <div className="containerMontemoro">
        <div className="items-start mt-16 md:flex md:space-x-10">
          <div
            // data-aos="fade-down"
            className="relative hidden border md:block mt-14 md:w-4/12"
          >
            <div className="absolute z-0 w-32 h-32 rounded-full left-4 -top-12 animate-pulse bg-[#33EFA0]"></div>
            <div className="absolute z-10 w-5 h-5 rounded-full left-36 -top-12 animate-ping  bg-[#33D9EF] "></div>
            <div className="absolute z-10 rounded-full w-36 h-36 right-16 -bottom-6 animate-pulse  bg-[#5B61EB]"></div>
            <div className="absolute z-20 w-5 h-5 rounded-full right-52 bottom-1 animate-ping  bg-[#F56666]"></div>
            <img
              className="relative z-5 floating"
              src="img/crm/crm-intro.png"
              alt=""
            />
          </div>

          <div
            data-aos="fade-down"
            className="mt-20 text-gray-500 md:w-4/12 md:mt-0"
          >
          
            <SmallLinks
              icon={FaPeopleArrows}
              title="Opis programu CRM"
              link={linkCrmInfo}
            />
            <SmallLinks
              icon={FcAddImage}
              title="Dodawanie projektów"
              link={linkCrmProjectAdd}
            />

            <SmallLinks
              icon={FcBinoculars}
              title="Przeglądanie projektów"
              link={linkCrmProjectView}
            />

            <SmallLinks
              icon={FcEditImage}
              title="Edycja projektów"
              link={linkCrmProjectEdit}
            />

            <SmallLinks
              icon={FcEngineering}
              title="Inne czynnosci"
              link={linkCrmProjectOthers}
            />
          </div>

          <div
            data-aos="fade-down"
            className="mt-20 text-gray-500 md:w-4/12 md:mt-0"
          >
            <SmallLinks
              icon={FcAreaChart}
              title="Obsługa harmonogramów przychodów"
              link={linkCrmProjectSchedule}
            />

            <SmallLinks
              icon={BsFillCalendarCheckFill}
              title="Obsługa kalendarza i zdarzeń"
              link={linkCrmCalendar}
            />

            <SmallLinks
              icon={TbZoomMoney}
              title="Szczegółowe analizy finansowe"
              link={linkCrmFinansialAnalysis}
            />

            <SmallLinks
              icon={IoIosPeople}
              title="Zarządzanie Klientami i użytkownikami"
              link={linkCrmUserManagement}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CrmLinks;
