import React from "react";

import SmallBoxInfoLeft from "../tools/SmallBoxInfoLeft";
import SmallBoxInfoRight from "../tools/SmallBoxInfoRight";
import SmallLinks from "../tools/SmallLinks";
import VideoBox from "../tools/VideoBox";
import CrmLinks from "./CrmLinks";
import SodLinks from "../sod/SodLinks";

const CrmConfigProgram = ({ fullInfo = false, links = false, without }) => {
  return (
    <div className="containerMontemoro">
      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div data-aos="fade-right" className="relative sm:w-1/2">
          <div className="absolute z-0 w-12 h-12 bg-yellow-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
            Konfiguracja Programu CRM
          </h1>
        </div>
      </div>

      <div
        data-aos="zoom-in-up"
        className="flex flex-col my-14 lg:flex-row lg:space-x-20"
      >
        <div className="lg:w-6/12">
          <SmallBoxInfoLeft
            html="
            <div class='sod-config-list'>
            <p>Konfiguracja jest bardzo prosta. Na infografice zostały przedstawione niezbędne kroki wymagane do poprawnej pracy:</p>
     
            <ol class='' >
            <li><a href='#userConfig'>Konfiguracja użytkowników</a></li>
            <li><a href='#deptConfig'>Konfiguracja departamentów</a></li>
            <li><a href='#flowConfig'>Konfiguracja projektów</a></li>
            <li><a href='#formConfig'>Konfiguracja planowanych przychodów</a></li>
            </ol>
            <p>Po tych czynnościach możemy przejść do przeprowadzenia:</p>
            <span><a href ='/sod-proces-obiegu'>obiegu dokumentów</a></span>
            </div>
            "
          />
        </div>

        <div className="flex flex-col justify-between mt-12 space-y-5 lg:w-7/12 lg:space-y-0 lg:mt-0">
          <img className="mx-auto" src="img/crm/crm-config.svg" />
        
        </div>
      </div>
      
      {links && <CrmLinks without={without} />}
    </div>
  );
};

export default CrmConfigProgram;
