import React  from 'react';

const Contact = () => {
  return (
    <div className="containerMontemoro">
    <div className="container max-w-screen-xl px-4 mx-auto mb-10 overflow-hidden text-gray-700 lg:px-8">
      <div className="flex flex-col-reverse items-center mt-20 md:flex-row md:space-x-10">
        <div data-aos="fade-right" className="md:w-6/12">
          <img className="mt-10 md:mt-0 md:w-11/12 rounded-2xl" src="img/offer2.jpg" alt = "Montemoro - najlepsze programy CRM i SOD" />
        </div>
        <div
          data-aos="fade-left"
          className="md:w-6/12 md:transform md:-translate-y-20"
        >
          <h1 className="text-3xl font-semibold text-darken">
            Zapraszamy<span className="textOrange"> do kontaktu</span>
          </h1>
          <p className="my-5 text-gray-500 lg:pr-52">
            Montemoro
          </p>
          <p className="my-5 text-gray-500 lg:pr-52">
            tel. +694 230 722
          </p>
          <p className="my-5 text-gray-500 lg:pr-52">
            mail: biuro@montemoro.pl
          </p>
          <p className="font-bold">Chętnie odpowiemy na wszystkie pytania.</p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Contact;
