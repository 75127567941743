import React  from 'react';

import SmallBoxInfoLeft from "../tools/SmallBoxInfoLeft";
import SmallBoxInfoRight from "../tools/SmallBoxInfoRight";
import SmallLinks from "../tools/SmallLinks";
import VideoBox from "../tools/VideoBox";
import CrmLinks from "./CrmLinks";

const CrmProjectView = ({ fullInfo = false, links = false, without }) => {
  return (
    <div className="containerMontemoro">
    <div className="items-center sm:flex sm:space-x-8 mt-36">
      <div data-aos="fade-right" className="relative sm:w-1/2">
        <div className="absolute z-0 w-12 h-12 bg-yellow-500 rounded-full -left-4 -top-3 animate-pulse"></div>
        <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
          Przeglądanie projektów
        </h1>
      </div>
    </div>

    <div
      data-aos="zoom-in-up"
      className="flex flex-col my-14 lg:flex-row lg:space-x-20"
    >
      <div className="lg:w-6/12">
        <SmallBoxInfoLeft
          h1="Projekty możemy przegladać na liście. Podobnie jak dla każdej innej listy  mamy tutaj możliwości sortowania i filtrowania"
          img="img/crm/project-list.png"
        />
         <SmallLinks
            link="crm-przegladanie-projektow"
            title = "Zobacz więcej o przeglądaniu projektów"
            seeMore={true}
            show = {!fullInfo}
          />
      </div>

      <div className="flex flex-col justify-between mt-12 space-y-5 lg:w-7/12 lg:space-y-0 lg:mt-0">
        <SmallBoxInfoRight
          img="img/crm/project-filter.png"
          h1="Przykład sortowania malejącego lub rosnącego"
          info_name="Uwaga"
          info="Te same mechanizmy sortowania, filtrowania a także stronicowania znajdziemy we wszystkich listach do przegladania"
        />

        <SmallBoxInfoRight
          img="img/crm/project-filter2.png"
          h1="Przykład filtrowania listy"
        />
      </div>
    </div>
    {fullInfo && <VideoBox
      h1="Zobacz film z możliwościami przeglądania projektów takimi jak sortowanie i filtrowanie"
      video="crm/project-list"
    />}
      {links && <CrmLinks without={without} />}
  </div>
  );
};

export default CrmProjectView;
