import React  from 'react';

import SmallBoxInfoLeft from "../tools/SmallBoxInfoLeft";
import SmallBoxInfoRight from "../tools/SmallBoxInfoRight";
import SmallLinks from "../tools/SmallLinks";
import VideoBox from "../tools/VideoBox";
import CrmLinks from "./CrmLinks";

const CrmProjectAdd = ({ fullInfo = false, links = false, without }) => {
  return (
    <div className="containerMontemoro">
      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div data-aos="fade-right" className="relative sm:w-1/2">
          <div className="absolute z-0 w-12 h-12 bg-yellow-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
            Dodawanie Projektu
          </h1>
        </div>
      </div>

      <div
        data-aos="zoom-in-up"
        className="flex flex-col my-14 lg:flex-row lg:space-x-20"
      >
        <div className="lg:w-6/12">
          <SmallBoxInfoLeft
            h1="Zakładając, że wcześniej skonfigurowaliśmy wszystkie parametry systemu (więcej o tym w dalszej części) możemy dodać nowy projekt do naszego systemu."
            img="img/crm/new-project.png"
          />
            <SmallLinks
            link="crm-dodawanie-projektu"
            title = "Zobacz więcej o dodawaniu projektów"
            seeMore={true}
            show = {!fullInfo}
          />
        </div>

        <div className="flex flex-col justify-between mt-12 space-y-5 lg:w-7/12 lg:space-y-0 lg:mt-0">
          <SmallBoxInfoRight
            img="img/crm/clients.png"
            h1="Podstawowe parametry projektu to: klient, którego dotyczy projekt, nazwa projektu i ewentualnie jego numer, data założenia projektu"
          />

          <SmallBoxInfoRight
            img="img/crm/project-type.png"
            h1="Opcjonalnie możemy wybrać okres w jakim projekt będzie funkcjonował. Ze słownika typu projektu możemy wybrać typ projektu"
            p="Więcej o słownikach w dalszej częsci opisu."
          />

          <SmallBoxInfoRight
            img="img/crm/color.png"
            h1="Możemy też wybrać kolor projektu. Ten kolor będzie się pokazywał w kalendarzu przy zdarzeniach związanych z tym projektem."
          />
        
        </div>
      </div>
      {fullInfo && (
        <VideoBox
          h1="Zobacz film jak dodawać nowy projekt"
          video="crm/project-add"
        />
      )}
      {links && <CrmLinks without={without} />}
    </div>
  );
};

export default CrmProjectAdd;


