import React  from 'react';

import SmallBoxInfoLeft from "../tools/SmallBoxInfoLeft";
import SmallBoxInfoRight from "../tools/SmallBoxInfoRight";
import SmallLinks from "../tools/SmallLinks";
import VideoBox from "../tools/VideoBox";
import CrmLinks from "./CrmLinks";

const CrmProjectOthers = ({ fullInfo = false, links = false, without }) => {
  return (
    <div className="containerMontemoro">
      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div data-aos="fade-right" className="relative sm:w-1/2">
          <div className="absolute z-0 w-12 h-12 bg-yellow-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
            Inne czynności związane z projektami
          </h1>
        </div>
      </div>

      <div
        data-aos="zoom-in-up"
        className="flex flex-col my-14 lg:flex-row lg:space-x-20"
      >
        <div className="lg:w-6/12">
          <SmallBoxInfoLeft
            h1="Możemy przeprowadać także inne czynności związane z projektami jak: dodawanie notatek związanych z Klientem oraz załączników"
            img="img/crm/project-changes.png"
          />
            <SmallLinks
            link="crm-inne-czynnosci"
            title = "Zobacz więcej o innych czynnościach związanych z projektami"
            seeMore={true}
            show = {!fullInfo}
          />
        </div>

        <div className="flex flex-col justify-between mt-12 space-y-5 lg:w-6/12 lg:space-y-0 lg:mt-0">
          <SmallBoxInfoRight
            img="img/crm/harmonogram-edit-one.png"
            h1="Możemy przeglądać historię aktywności związanych z projektem"
          />

          <SmallBoxInfoRight
            img="img/crm/project-duplicate.png"
            h1="Jeżeli tworzymy podobny projekt podobny do innego to mamy możliwość powielenia istniejącego projektu"
          />

          <SmallBoxInfoRight
            img="img/crm/project-person.png"
            h1="Mamy możliwość dodania do projektu osobę Zarządzająca Projektem"
          />
        </div>
      </div>
       {fullInfo && (
        <VideoBox h1="Zobacz film z innych operacji związanych z projektami"
        video="crm/project-others" />
      )}
      {links && <CrmLinks without={without} />}
      
    </div>
  );
};

export default CrmProjectOthers;
