import React from "react";
import { Navigate } from "react-router-dom";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import EveryThing from "./components/EveryThing";
import UserInterface from "./components/UserInterface";
import Tools from "./components/Tools";
import ClassManagement from "./components/ClassManagement";
import OneOnOne from "./components/OneOnOne";
import Integrations from "./components/Integrations";
import Testimonials from "./components/Testimonials";
import LatestNews from "./components/LatestNews";

import NavBarMain from "./pages/Nav/NavBarMain";
import BgCream from "./pages/StartPage";
import Technologies from "./pages/Technologies";
import Offer from "./pages/Offer";
import Software1 from "./pages/Software1";
import Software2 from "./pages/Software2";
import Quizes from "./components/Quizes";
import Footer from "./pages/Footer";
import Terms from "./pages/Terms";
import AboutUs from "./pages/AboutUs";
import SeeMore from "./pages/tools/SeeMore";
import Contact from "./pages/Contact";
import ArrowUp from "./pages/tools/ArrowUp";
import CrmProjectAdd from "./pages/crm/CrmProjectAdd";
import CrmProjectView from "./pages/crm/CrmProjectView";
import CrmProjectEdit from "./pages/crm/CrmProjectEdit";
import CrmProjectSchedule from "./pages/crm/CrmProjectSchedule";
import CrmFinansialAnalysis from "./pages/crm/CrmFinansialAnalysis";
import CrmProjectOthers from "./pages/crm/CrmProjectOthers";
import CrmIntro from "./pages/crm/CrmIntro";
import SoftwareContact from "./pages/SoftwareContact";
import CrmLinks from "./pages/crm/CrmLinks";
import CrmCalendar from "./pages/crm/CrmCalendar";
import UserManagement from "./pages/crm/CrmUserManagement";
import SEO from "./components/SEO";
import Error404 from "./pages/Error404";
import SodIntro from "./pages/sod/SodIntro";
import SodFlowConfig from "./pages/sod/SodFlowConfig";
import SodLinks from "./pages/sod/SodLinks";
import SodFlowForm from "./pages/sod/SodFlowForm";
import SodDepertmentConfig from "./pages/sod/SodDeptConfig";
import SodDeptConfig from "./pages/sod/SodDeptConfig";
import SodFlowStart from "./pages/sod/SodFlowProces";
import ContactForm from "./pages/ContactForm";
import SodFlowConfigProgram from "./pages/sod/SodFlowConfigProgram";
import CrmConfigProgram from "./pages/crm/CrmConfigProgram";

function App() {
  return (
    <>
      <>
        <BrowserRouter>
          <NavBarMain />

          <Routes>
            <Route path="/contact/" element={<Navigate to="/kontakt" />} />
            {/* <Route path='/dupa/' element={ <Navigate to="/crm/" /> }/> */}
            <Route
              path="/"
              element={
                <>
                  <BgCream />
                  <Software1 />
                  <Software2 />
                  <AboutUs />
                  <Technologies />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/terms"
              element={
                <>
                  <Terms />
                </>
              }
            />
            <Route
              path="/software"
              element={
                <>
                  <Software1 />
                  <Software2 />
                  <SEO />
                </>
              }
            />
            <Route
              path="/o-nas"
              element={
                <>
                  <AboutUs />
                  <SeeMore />
                  <Software1 />
                  <Software2 />
                  <SEO />
                </>
              }
            />
            <Route
              path="/crm"
              element={
                <>
                  <CrmIntro without="CrmInfo" />
                  <CrmProjectAdd />
                  <CrmProjectView />
                  <CrmProjectEdit />
                  <CrmProjectOthers />

                  <CrmProjectSchedule />
                  <CrmCalendar />
                  <CrmFinansialAnalysis />
                  <UserManagement />
                  <CrmLinks
                    title="Zobacz więcej o programie CRM"
                    without="CrmInfo"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            
            <Route
              path="/crm-konfiguracja-programu"
              element={
                <>
                  <CrmConfigProgram
                    fullInfo={true}
                    links={true}
                    without="CrmProjectAdd"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
                />
            <Route
              path="/crm-dodawanie-projektu"
              element={
                <>
                  <CrmProjectAdd
                    fullInfo={true}
                    links={true}
                    without="CrmProjectAdd"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/crm-przegladanie-projektow"
              element={
                <>
                  <CrmProjectView
                    fullInfo={true}
                    links={true}
                    without="CrmProjectView"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/crm-edycja-projektu"
              element={
                <>
                  <CrmProjectEdit
                    fullInfo={true}
                    links={true}
                    without="CrmProjectEdit"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/crm-harmonogram-przychodow"
              element={
                <>
                  <CrmProjectSchedule
                    fullInfo={true}
                    links={true}
                    without="CrmProjectSchedule"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/crm-analiza-finansowa"
              element={
                <>
                  <CrmFinansialAnalysis
                    fullInfo={true}
                    links={true}
                    without="CrmFinansialAnalysis"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/crm-kalendarz"
              element={
                <>
                  <CrmCalendar
                    fullInfo={true}
                    links={true}
                    without="CrmCalendar"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/crm-zarzadzanie-uzytkownikami"
              element={
                <>
                  <UserManagement
                    fullInfo={true}
                    links={true}
                    without="CrmUserManagement"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/crm-inne-czynnosci"
              element={
                <>
                  <CrmProjectOthers
                    fullInfo={true}
                    links={true}
                    without="CrmProjectOthers"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/sod"
              element={
                <>
                  <SodIntro />
                  {/* <SodFlowConfigProgram />
                  <UserManagement />
                  <SodDepertmentConfig />
                  <SodFlowConfig />
                  <SodFlowForm />
                  <SodFlowStart /> */}
                  <SodLinks
                    title="Zobacz więcej o programie SOD"
                    without="SodInfo"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/sod-konfiguracja-programu"
              element={
                <>
                  <SodFlowConfigProgram
                    fullInfo={false}
                    links={false}
                    without="SodConfigProgram"
                  />
                  <UserManagement />
                  <SodDepertmentConfig />
                  <SodFlowConfig />
                  <SodFlowForm />
                  <SodLinks
                    title="Zobacz więcej o programie SOD"
                    without="SodConfigProgram"
                    />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/sod-zarzadzanie-uzytkownikami"
              element={
                <>
                  <UserManagement
                    fullInfo={true}
                    links={true}
                    without="CrmUserManagement"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/sod-konfiguracja-obiegu"
              element={
                <>
                  <SodFlowConfig
                    fullInfo={true}
                    links={true}
                    without="SodConfigFlow"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/sod-konfiguracja-departamentu"
              element={
                <>
                  <SodDeptConfig
                    fullInfo={true}
                    links={true}
                    without="SodConfigDept"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/sod-konfiguracja-pol-formularza"
              element={
                <>
                  <SodFlowForm
                    fullInfo={true}
                    links={true}
                    without="SodConfigForm"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />
            <Route
              path="/sod-proces-obiegu"
              element={
                <>
                  <SodFlowStart
                    fullInfo={true}
                    links={true}
                    without="SodFlow"
                  />
                  <SoftwareContact />
                  <SEO />
                </>
              }
            />

            <Route
              path="/oferta"
              element={
                <>
                  <Offer />
                  <Contact />
                  <SeeMore />
                  <Software1 without="offer" />
                  <Software2 />
                  <SoftwareContact without="offer" />
                  <SEO />
                </>
              }
            />

            <Route
              path="/kontakt"
              element={
                <>
                  <Contact />
                  <SeeMore />
                  <Software1 />
                  <Software2 />
                  <SEO />
                </>
              }
            />
            <Route
              path="/formularz"
              element={
                <>
                  <ContactForm />
                  <SEO />
                </>
              }
            />
            <Route path="/terms" element={<Quizes />} />
            <Route
              path="/test"
              element={
                <>
                  <EveryThing />
                  <Quizes />
                  <UserInterface />
                  <Tools />
                  <ClassManagement />
                  <OneOnOne />
                  <Integrations />
                  <Testimonials />
                  <LatestNews />
                  <ArrowUp />
                </>
              }
            />
            <Route
              path="*"
              element={
                <>
                  <Error404 />
                  <SeeMore title="Zobacz w takim razie nasze programy" />
                  <Software1 />
                  <Software2 />
                  <SeeMore title="A kontakt do nas to" />
                  <Contact />
                </>
              }
            />
          </Routes>
        </BrowserRouter>
        <Footer />
      </>
    </>
  );
}

export default App;
