import React from "react";

import { MdSportsTennis } from "react-icons/md";
import { MdSportsVolleyball } from "react-icons/md";

const AboutUs = () => {
  return (
    <div className="containerMontemoro about">
      <div
        id="aboutus"
        className="flex flex-col items-center p-10 my-10 mb-20 shadow-xl rounded-xl md:flex-row md:space-x-10"
      >
        <div
          data-aos="fade-right"
          className="text-gray-500 aboutus md:w-1/2 lg:pl-14"
        >
          <h1 className="text-3xl font-semibold text-darken lg:pr-56">
            <span className="textOrange">O</span> nas
          </h1>
          <p className="my-4">
            Jesteśmy małą firmą działającą od kilkunastu lat. Jednak w ostatnim
            notujemy gwałtowne zainteresowanie i rozwój naszej Firmy.
          </p>
          <p>Naszymi sztandarowymi produktami to:</p>
          <ul className="linkInText">
            <li>
              <a href="/crm">System CRM</a>
            </li>
            <li>
              <a href="/sod">System Obiegu Dokumentów</a>
            </li>
          </ul>
          <p>
            Te programy rozwijamy dynamicznie od początku naszej działalności.
          </p>
          <p>
            Oprócz programów sprzedawanych seryjnie stworzyliśmy wiele programów
            na życzenie Klienta. Naszymi Klientami są m.in. duże firmy z branży
            energetycznej i branży transportowej.
          </p>
          <p>
            Nowością w naszej ofercie jest program do obsługi lig sportowych.{" "}
            <MdSportsTennis className="inline text-2xl text-[#66e047] animate-pulse" />
            <MdSportsVolleyball className="inline text-2xl text-[#194247] animate-pulse" />{" "}
            Na początku skupiamy się na tenisie i piłce nożnej, ale już
            planujemy kolejne dyscypliny.
          </p>
          <p className="about">
            Wkrótce planujemy także dodać dokładniejsze opisy naszych produktów
            i wersje demonstracyjne programów
          </p>
        </div>
        <img
          data-aos="fade-left"
          className="mt-10 rounded-lg md:mt-0 md:w-1/2"
          src="img/screen2-sm.jpg"
          alt="Montemoro - producent oprogramowania"
        />
      </div>
    </div>
  );
};

export default AboutUs;
