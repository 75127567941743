import React from "react";

import SmallBoxInfoLeft from "../tools/SmallBoxInfoLeft";
import SmallBoxInfoRight from "../tools/SmallBoxInfoRight";
import SmallLinks from "../tools/SmallLinks";
import VideoBox from "../tools/VideoBox";
import CrmLinks from "../crm/CrmLinks";
import SodLinks from "./SodLinks";

const SodFlowForm = ({ fullInfo = false, links = false, without }) => {
  return (
    <div id="formConfig" className="containerMontemoro">
      <div className="items-center sm:flex sm:space-x-8 mt-36">
        <div data-aos="fade-right" className="relative sm:w-1/2">
          <div className="absolute z-0 w-12 h-12 bg-yellow-500 rounded-full -left-4 -top-3 animate-pulse"></div>
          <h1 className="relative z-50 text-2xl font-semibold text-darken lg:pr-10">
            Definiowanie pól formularza używanych w obiegu
          </h1>
        </div>
      </div>

      <div
        data-aos="zoom-in-up"
        className="flex flex-col my-14 lg:flex-row lg:space-x-20"
      >
        <div className="lg:w-6/12">
          <SmallBoxInfoLeft
            h1="Kolejnym krokiem jest zdefiniowanie pól formularza. Dla każdego typu obiegu ( w tym wypadku dla FZ) definiujemy odpowiednie pola. Mamy dostep do pól takich jak: Kontrahent, daty, któtkie lub długie pola tekstowe, czy nr projektu."
            img="img/sod/form-typ.png"
          />
          <SmallLinks
            link="sod-konfiguracja-pol-formularza"
            title="Jak zdefiniować pola formularza i jak te pola są widoczne podczas obiegu dokumentu"
            seeMore={true}
            show={!fullInfo}
          />
        </div>

        <div className="flex flex-col justify-between mt-12 space-y-5 lg:w-7/12 lg:space-y-0 lg:mt-0">
          <SmallBoxInfoRight
            img="img/sod/flow-form-table.png"
            h1="Możliwość przeglądu zdefiniowanych pól formularza."
            // p="Więcej o słownikach w dalszej częsci opisu."
          />

          <SmallBoxInfoRight
            img="img/sod/form-order.png"
            h1="Możemy ustawiać pola jako wymagane lub niewymagane oraz zmieniać kolejnośc pól."
          />

          <SmallBoxInfoRight
            img="img/sod/form-width.png"
            h1="Możemy ustawić także szerokość pól aby dobrze się ładnie formularz wyświetlał na ekranie."
          />
        </div>
      </div>
      {fullInfo && (
        <VideoBox
          h1="Zobacz film w jaki sposób dodaliśmy pola dla tej Faktury Zakupu:"
          video="sod/sod-form-config"
        />
      )}
      {links && <SodLinks without={without} />}
    </div>
  );
};

export default SodFlowForm;
