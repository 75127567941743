import React  from 'react';

import { FaReact } from "react-icons/fa";
import { DiNodejs } from "react-icons/di";
import { DiPostgresql } from "react-icons/di";
import { FaDocker } from "react-icons/fa";
import { FcLinux } from "react-icons/fc";
import { FaPhp } from "react-icons/fa";
import { DiJavascript } from "react-icons/di";
import { FaPython } from "react-icons/fa";
import { FaAws } from "react-icons/fa";
import { SiOvh } from "react-icons/si";
import { TbBrandTailwind } from "react-icons/tb";

const Technologies = () => {
  return (
    <div className="containerMontemoro">
      <div
        id="technologies"
        className="items-center p-10 mt-10 mb-10 shadow-xl rounded-xl md:flex-row md:space-x-10"
      >
        {/* <div className="max-w-4xl mx-auto"> */}
        <div className="w-full">
          <h1
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-once="true"
            className="mt-0 mb-10 text-xl text-center text-[#1a2c41] font-large font-semibold"
          >
            Nasze technologie
          </h1>
        </div>
        <div className="grid w-full grid-cols-3 gap-4 lg:grid-cols-6 justify-items-center">
          <FaReact
            className="text-[#1a2c41] text-7xl animate-pulse"
            title="React/React Native"
          />
          <DiNodejs className=" text-[#1a2c41] text-7xl" title="Node.js" />
          <DiJavascript className="text-[#1a2c41] text-7xl"  title="JavaScript"/>
          <TbBrandTailwind className="text-[#457a8f] text-7xl" title="TailWind"/>
          <DiPostgresql className="text-[#457a8f] text-7xl animate-pulse" title="PostgreSQL"/>
          <FaPhp className="text-[#457a8f] text-7xl" title="PHP"/>
          <FaDocker className="text-[#457a8f] text-7xl" title="Docker"/>
          <FcLinux className="text-[#457a8f] text-7xl animate-pulse" title="Linux"/>
          <FaPython className="text-[#457a8f] text-7xl" title="Python"/>
          <FaAws className="text-[#457a8f] text-7xl" title="AWS"/>
          <SiOvh className="text-[#457a8f] text-7xl" title="OVH"/>
        </div>
      </div>
    </div>
  );
};

export default Technologies;
