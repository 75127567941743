import { Helmet, HelmetProvider } from "react-helmet-async";
import { SeoTab } from "../config/Config";

const SEO = () => {
  let link = window.location.pathname.substring(1);
  if (link === "") link = "home";
let item =SeoTab.filter(pos => pos.key === link)[0]

  if (typeof item === "undefined") return;

  return (
    <HelmetProvider>

    <Helmet>
      <title>{item.title}</title>
      <meta name="description" content={item.desc} />
      {/* <meta name="theme-color" content="#008f68" /> */}
      {/* <body style="background:yellow; border:3px solid red;" class="dark" /> */}
    </Helmet>
    </HelmetProvider>
  );
};

export default SEO;
